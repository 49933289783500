import Vue from 'vue';
import Vuex from 'vuex';
import BaseMapViewer from '../base_map_viewer/BaseMapViewer.vue';
import store from './store/store';
import mapboxgl, { Style } from "mapbox-gl";
import { GridRef, GridRefUtils } from "../utils/helpers/GridRefPreference";
import mapToken from '../utils/helpers/MaptilerToken'
import { MapOverlayOption } from "../utils/models/MapOverlayOptions";
import { LeftColumnViewModel } from "../utils/models/LeftColumnViewModel";
import { bbox } from "@turf/turf";
import createGeoLocate from "../utils/helpers/GeoLocateHelper";
import {HiikerMapStyle} from "../utils/models/MapStyles";
import 'mapbox-gl/dist/mapbox-gl.css';
import {RevenueCatUtil} from "../utils/models/RevenueCatUser";
import {addStartAndEndMarkers} from "../utils/helpers/StartEndMarkerHelper";
import {RevenueCatWrapper} from "../utils/RevenueCatWrapper";

Vue.use(Vuex)

declare global {
  interface Window {
    vue: Vue;
    isPro: boolean;
    isProPlus: boolean;
    rcPublicKey: string;
  }
}

if (document.querySelector('#recorded-trail-web-viewer')) {
  window.vue = new Vue({
    store,
    render: (createElement) => {
      return createElement(BaseMapViewer, {
        props: {
          // @ts-ignore
          chartData: store.state.recordedTrail.chartData
        },
      })
    },
    async beforeCreate() {
      await this.$store.dispatch('recordedTrail/initialiseStore');
      await this.$store.dispatch('mapStyles/initialiseStore');
      // @ts-ignore
      this.setBaseMapLeftColumnViewModel()
    },
    async mounted() {
      await this.initialiseMap()
    },
    methods: {
      setBaseMapLeftColumnViewModel() {
        const recording = this.$store.state.recordedTrail.recording
        const lineString = this.$store.state.recordedTrail.lineString
        // @ts-ignore
        const isOwnTrail = window.user != null && window.user.id === recording.user_id
        const leftColumnViewModel = LeftColumnViewModel.forRecordedTrail(recording, lineString, isOwnTrail)
        this.$store.commit("baseMap/setLeftColumnViewModel", leftColumnViewModel);
      },
      async initialiseMap() {
        mapboxgl.accessToken = "pk.eyJ1IjoiaGlpa2VyLW1hcHMiLCJhIjoiY2x0YThyZHppMWhmejJqbzExODBuY3dveiJ9.nBrtnXgbzxpAx_953HCB-w"
        const map = new mapboxgl.Map(this.$store.getters["recordedTrail/mapOptions"]);
        const isProOrProPlus = await RevenueCatWrapper.isProOrProPlus();
        map.setStyle(HiikerMapStyle.getCurrentStyle(isProOrProPlus).styleUrl)
        const gridRef = GridRefUtils.preference()
        map.on('style.load', this.mapStyleLoaded);
        map.once('style.load', () => {
          if (store.state.recordedTrail.lineString != null) {
            this.setMapBounds(store.state.recordedTrail.lineString)
          }
        })
        map.on('mousemove', (e) => {
          const coords = gridRef.convert(e.lngLat);
          store.commit('baseMap/setMiniCoordsBar', coords.toString())
        });
        map.on('load' , () => {
          createGeoLocate();
        });
        window.map = map;
        this.$store.dispatch('waypoints/initialiseStore');
      },
      setMapBounds(geoJson: any) {
        const map = window.map
        const newBbox = bbox(geoJson);
        // @ts-ignore

        const bounds = mapboxgl.LngLatBounds.convert(newBbox);
        map.fitBounds(bounds, {
          padding: 200
        });
      },
      mapStyleLoaded() {
        setTimeout(() => {
          this.addPolyline()
        }, 1000)
      },
      addPolyline() {
        let id = 'lineString'
        if (window.map.getSource(id) == null) {
          window.map.addSource(id, {
            type: 'geojson',
            data: this.$store.state.recordedTrail.lineString,
          });
        }
        if (window.map.getLayer(id)) {
          window.map.removeLayer(id)
        }
        window.map.addLayer({
          id: id,
          type: 'line',
          source: id,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': 'red',
            'line-width': 4,
          },
        });
        const start = this.$store.state.recordedTrail.lineString.features[0].geometry.coordinates[0]
        const end = this.$store.state.recordedTrail.lineString.features[0].geometry.coordinates.slice(-1)[0]
        addStartAndEndMarkers(start, end)
      },
    },
  }).$mount('#recorded-trail-web-viewer');
}
