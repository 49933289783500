import { HiikerGeoJSON } from "../../../utils/models/HiikerGeoJson";
import Vue from "vue";
import { ActionTree, Module } from "vuex";
import store, { RootState } from "../store";
import { HiikerPlannedTrail } from "../../../utils/models/HiikerPlannedTrail";

declare global {
  interface Window {
    plannedTrail: HiikerPlannedTrail;
    lineString: HiikerGeoJSON;
    copyUrl: string;
    user: object | null;
    units: string;
    gridRef: string;
  }
}

export class PlannedTrailState {
  // @ts-ignore
  copyUrl: string;
  plannedTrail: HiikerPlannedTrail | null;
  lineString: HiikerGeoJSON | null;
  chartData: object | null = null;

  constructor(plannedTrail: HiikerPlannedTrail | null, lineString: HiikerGeoJSON | null) {
    this.plannedTrail = plannedTrail;
    this.lineString = lineString;
  }
}

const state = Vue.observable(new PlannedTrailState(null, null));

const actions: ActionTree<PlannedTrailState, RootState> = {
  async initialiseStore({ state }) {
    // @ts-ignore
    state.userLoggedIn = window.userLoggedIn;
    state.plannedTrail = HiikerPlannedTrail.fromJson(window.plannedTrail)
    state.lineString = HiikerGeoJSON.fromPlannedTrailJson(window.lineString)
    state.chartData = state.lineString.toHiikerChartData();
    state.copyUrl = window.copyUrl
  },
}


const mutations = {
  setPlannedTrail(state: PlannedTrailState, plannedTrail: HiikerPlannedTrail) {
    state.plannedTrail = plannedTrail
  },
  setLineString(state: PlannedTrailState, lineString: HiikerGeoJSON) {
    state.lineString = lineString
  },
  setCopyUrl(state: PlannedTrailState, copyUrl: string) {
    state.copyUrl = copyUrl
  }
}

const getters = {
  firstCoordinate(state: PlannedTrailState) {
    let firstCoordinates = state.lineString?.features[0].geometry.coordinates[0]
    if (firstCoordinates) {
      return [firstCoordinates[0], firstCoordinates[1]]
    } else {
      return [0.0, 0.0]
    }
  },
  mapOptions(state: PlannedTrailState): object {
    return {
      container: 'map', // container ID
      center: store.getters['plannedTrail/firstCoordinate'], // starting position [lng, lat] // extract from lineString data
      zoom: 10, // starting zoom
      maxPitch: 60, // limit the pitch to 85 degrees
    }
  }
};

const modules = {

}

export default class PlannedTrailModule implements Module<PlannedTrailState, RootState> {
  namespaced = true
  state = state
  actions = actions
  mutations = mutations
  getters = getters
  modules = modules
}
