import {MeasurementPrefUtility} from "../helpers/MeasurementPref";
import {SecondsFormatter} from "../helpers/SecondsFormatter";
import {HiikerGeoJSON} from "./HiikerGeoJson";
import {HiikerRecordedTrail} from "./HiikerRecordedTrail";
import {HiikerPlannedTrail} from "./HiikerPlannedTrail";
import {TrailTypes} from "./TrailTypes";

export class LeftColumnViewModel {
  title: string
  created_at: string
  distanceMeters: number
  elevationGainMeters: number
  elapsedSeconds: number
  actionUrl: string | null
  actionTitle: string | null
  createdAt: Date | null
  lineString: HiikerGeoJSON | null
  isOwntrail: boolean
  trailType: TrailTypes
  thumbnailUrl: string | null
  editPath: string | null = null
  creating_user_title: string | null = null
  images: string[] = []

  constructor(
    title: string,
    created_at: string,
    distance: number,
    elevationGain: number,
    elapsedSeconds: number,
    actionUrl: string | null,
    actionTitle: string | null,
    createdAt: Date | null = null,
    lineString: HiikerGeoJSON | null = null,
    isOwntrail: boolean,
    trailType: TrailTypes,
    thumbnailUrl: string | null = null,
    editPath: string | null = null,
    creating_user_title: string | null = null,
    images: string[] = []
  ) {
    this.title = title
    this.created_at = created_at
    this.distanceMeters = distance
    this.elevationGainMeters = elevationGain
    this.elapsedSeconds = elapsedSeconds
    this.actionUrl = actionUrl
    this.actionTitle = actionTitle
    this.createdAt = createdAt
    this.lineString = lineString
    this.isOwntrail = isOwntrail
    this.trailType = trailType
    this.thumbnailUrl = thumbnailUrl
    this.editPath = editPath
    this.creating_user_title = creating_user_title
    this.images = images
  }

  shouldShowLeftBar(): boolean {
    if (this.actionUrl != null && this.actionTitle == null) {
      throw new Error("actionUrl is set but actionTitle is not. Please set both.")
    }
    return this.actionUrl !== null && this.actionTitle !== null
  }

  prettyElevationGain(): string {
    const pref = MeasurementPrefUtility.preference()
    return pref.smallUnitFormatter(this.elevationGainMeters)
  }

  prettyDistance(): string {
    const pref = MeasurementPrefUtility.preference()
    return pref.smallUnitFormatter(this.distanceMeters, true)
  }

  elevPerKM(): string {
    const pref = MeasurementPrefUtility.preference()
    return pref.smallUnitFormatter(this.elevationGainMeters / (this.distanceMeters / 1000), false)
  }

  prettyElapsedTime(): string {

    return SecondsFormatter.toHHMMSS(this.elapsedSeconds)
  }

  static forRecordedTrail(
    trail: HiikerRecordedTrail, lineString: HiikerGeoJSON,
    isOwnTrail: boolean
  ): LeftColumnViewModel {
    let leftColumnViewModel = trail.leftColumnViewModel(null, null, lineString)
    if (window.user == null) {
      return new LeftColumnViewModel(
        leftColumnViewModel.title,
        leftColumnViewModel.created_at,
        leftColumnViewModel.distanceMeters,
        leftColumnViewModel.elevationGainMeters,
        leftColumnViewModel.elapsedSeconds,
        "/login",
        "Login to save",
        trail.created_at,
        lineString,
        isOwnTrail,
        TrailTypes.RecordedTrail,
        trail.thumbnail_url,
        window.editPath,
        trail.creating_user_title,
        trail.images
      )
    } else if (isOwnTrail) {
      return new LeftColumnViewModel(
        leftColumnViewModel.title,
        leftColumnViewModel.created_at,
        leftColumnViewModel.distanceMeters,
        leftColumnViewModel.elevationGainMeters,
        leftColumnViewModel.elapsedSeconds,
        window.copyUrl,
        null,
        trail.created_at,
        lineString,
        isOwnTrail,
        TrailTypes.RecordedTrail,
        trail.thumbnail_url,
        window.editPath,
        trail.creating_user_title,
        trail.images
      )
    } else {
      return new LeftColumnViewModel(
        leftColumnViewModel.title,
        leftColumnViewModel.created_at,
        leftColumnViewModel.distanceMeters,
        leftColumnViewModel.elevationGainMeters,
        leftColumnViewModel.elapsedSeconds,
        window.copyUrl,
        "Save to planned trails",
        trail.created_at,
        lineString,
        isOwnTrail,
        TrailTypes.RecordedTrail,
        trail.thumbnail_url,
        window.editPath,
        trail.creating_user_title,
        trail.images
      )
    }
  }

  static forPlannedTrail(
    trail: HiikerPlannedTrail, lineString: HiikerGeoJSON,
    isOwnTrail: boolean
  ): LeftColumnViewModel {
    let leftColumnViewModel = trail.leftColumnViewModel(null, null, lineString)
    if (window.user == null) {
      return new LeftColumnViewModel(
        leftColumnViewModel.title,
        leftColumnViewModel.created_at,
        leftColumnViewModel.distanceMeters,
        leftColumnViewModel.elevationGainMeters,
        leftColumnViewModel.elapsedSeconds,
        "/login",
        "Login to save",
        trail.created_at,
        lineString,
        isOwnTrail,
        TrailTypes.PlannedTrail,
        trail.thumbnail_url,
        window.editPath,
        trail.creating_user_title
      )
    } else if (isOwnTrail) {
      return new LeftColumnViewModel(
        leftColumnViewModel.title,
        leftColumnViewModel.created_at,
        leftColumnViewModel.distanceMeters,
        leftColumnViewModel.elevationGainMeters,
        leftColumnViewModel.elapsedSeconds,
        null,
        null,
        trail.created_at,
        lineString,
        isOwnTrail,
        TrailTypes.PlannedTrail,
        trail.thumbnail_url,
        window.editPath,
        trail.creating_user_title
      )
    } else {
      return new LeftColumnViewModel(
        leftColumnViewModel.title,
        leftColumnViewModel.created_at,
        leftColumnViewModel.distanceMeters,
        leftColumnViewModel.elevationGainMeters,
        leftColumnViewModel.elapsedSeconds,
        window.copyUrl,
        "Save to planned trails",
        trail.created_at,
        lineString,
        isOwnTrail,
        TrailTypes.PlannedTrail,
        trail.thumbnail_url,
        window.editPath,
        trail.creating_user_title,
        trail.images
      )
    }
  }
}
