<template>
  <div style="height:90vh">
    <link href='https://api.mapbox.com/mapbox-gl-js/v3.8.0/mapbox-gl.css' rel='stylesheet' />
    <div class="row p-0 m-0 viewport-height-div">
      <div :class="this.leftColHidden ? 'd-none' : 'col-12 col-md-3 h-100'">
        <BaseMapViewerLeftColumn
          :class="this.leftColHidden ? 'd-none' : ''"
          :toggleMap="this.toggleMap"/>
      </div>
      <BaseMapViewerMap
        :left-col-hidden="this.leftColHidden"
        :toggle-map="this.toggleMap"
        map-styles-dialog-identifier="map-styles-dialog"
        :chart-data="chartData"/>
    </div>
    <BaseMapStylesDialog/>
    <MapDataDialog />
    <WaypointAddingNotification :enabled="waypointAddingModeEnabled" />
  </div>
</template>

<script lang="ts">

import { mapState } from 'vuex';
import BaseMapStylesDialog from '../base_map_styles_dialog/BaseMapStylesDialog.vue';
import WaypointAddingNotification from '../waypoint_adding_mode_notificaiton/WaypointAddingNotification.vue';
import BaseMapViewerMixin from './BaseMapViewerMixin';
import BaseMapViewerLeftColumn from './components/BaseMapViewerLeftColumn.vue';
import BaseMapViewerMap from './components/BaseMapViewerMap.vue';
import MapDataDialog from "../map_data/MapDataDialog.vue";

export default {
  mixins: [BaseMapViewerMixin],
  components: {
    MapDataDialog,
    WaypointAddingNotification,
    BaseMapViewerMap,
    BaseMapViewerLeftColumn,
    BaseMapStylesDialog,
  },
  data() {
    return {
      leftColHidden: false,
    };
  },
  props: {
    chartData: {
      type: Object,
    },
  },
  computed: {
    ...mapState({
      waypointAddingModeEnabled: (state) => state.waypoints.waypointAddingModeEnabled,
    }),
  },
  mounted() {
    this.updateDivHeight();
    window.addEventListener('resize', this.updateDivHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDivHeight);
  },
  methods: {
    toggleMap() {
      this.$data.leftColHidden = !this.$data.leftColHidden;
      setTimeout(() => {
        window.map.resize();
      }, 100);
    },
    updateDivHeight() {
      // Get the height of the viewport
      const viewportHeight = window.innerHeight;

      const div = this.$el.querySelector('.viewport-height-div');
      // Set the div height to the viewport height

      if (div) {
        div.style.height = `calc(${viewportHeight}px - 10vh)`;
      }

      console.log(div.style.height);
    },
  },
};
</script>

<style scoped>

</style>
