import {LeftColumnViewModel} from "./LeftColumnViewModel";
import {HiikerGeoJSON} from "./HiikerGeoJson";

export class HiikerRecordedTrail {
  id: number;
  title: string;
  body: string;
  user_id: number;
  created_at: Date;
  updated_at: Date;
  line_string: string;
  deleted: boolean;
  elapsed_seconds: number;
  time_stamps: any[];
  course_accuracy: number[];
  speed_accuracy: number[];
  vertical_accuracy: number[];
  horizontal_accuracy: number[];
  speed: number[];
  course: number[];
  obfuscated_id: number;
  public: boolean;
  trail_id?: any;
  planned_trail_id?: any;
  recording_id?: any;
  review_id?: any;
  elevation_gain_meters: number;
  elevation_data_mapped_at?: any;
  client_id: string;
  impressions_count: number;
  thumbnail_url: string;
  class: string;
  creating_user_title: string;
  images: string[] = [];


  constructor(
    id: number, title: string, body: string, user_id: number, created_at: Date, updated_at: Date, line_string: string,
    deleted: boolean, elapsed_seconds: number, time_stamps: any[], course_accuracy: number[], speed_accuracy: number[],
    vertical_accuracy: number[], horizontal_accuracy: number[], speed: number[], course: number[], obfuscated_id: number,
    mPublic: boolean, trail_id?: any, planned_trail_id?: any, recording_id?: any, review_id?: any,
    // @ts-ignore
    elevation_gain_meters: number,
    elevation_data_mapped_at?: any, client_id: string, impressions_count: number, thumbnail_url: string, mClass: string,
    creating_user_title: string,
    images: string[] = []
) {
    this.id = id;
    this.title = title;
    this.body = body;
    this.user_id = user_id;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.line_string = line_string;
    this.deleted = deleted;
    this.elapsed_seconds = elapsed_seconds;
    this.time_stamps = time_stamps;
    this.course_accuracy = course_accuracy;
    this.speed_accuracy = speed_accuracy;
    this.vertical_accuracy = vertical_accuracy;
    this.horizontal_accuracy = horizontal_accuracy;
    this.speed = speed;
    this.course = course;
    this.obfuscated_id = obfuscated_id;
    this.public = mPublic;
    this.trail_id = trail_id;
    this.planned_trail_id = planned_trail_id;
    this.recording_id = recording_id;
    this.review_id = review_id;
    this.elevation_gain_meters = elevation_gain_meters;
    this.elevation_data_mapped_at = elevation_data_mapped_at;
    this.client_id = client_id;
    this.impressions_count = impressions_count;
    this.thumbnail_url = thumbnail_url;
    this.class = mClass;
    this.creating_user_title = creating_user_title;
    this.images = images;
  }

  static fromJson(json: object) {
    // @ts-ignore
    return new HiikerRecordedTrail(
      // @ts-ignore
      json['id'],
      // @ts-ignore
      json['title'],
      // @ts-ignore
      json['body'],
      // @ts-ignore
      json['user_id'],
      // @ts-ignore
      new Date(json['created_at']),
      // @ts-ignore
      new Date(json['updated_at']),
      // @ts-ignore
      json['line_string'],
      // @ts-ignore
      json['deleted'],
      // @ts-ignore
      json['elapsed_seconds'],
      // @ts-ignore
      json['time_stamps'],
      // @ts-ignore
      json['course_accuracy'],
      // @ts-ignore
      json['speed_accuracy'],
      // @ts-ignore
      json['vertical_accuracy'],
      // @ts-ignore
      json['horizontal_accuracy'],
      // @ts-ignore
      json['speed'],
      // @ts-ignore
      json['course'],
      // @ts-ignore
      json['obfuscated_id'],
      // @ts-ignore
      json['public'],
      // @ts-ignore
      json['trail_id'],
      // @ts-ignore
      json['planned_trail_id'],
      // @ts-ignore
      json['recording_id'],
      // @ts-ignore
      json['review_id'],
      // @ts-ignore
      json['elevation_gain_meters'],
      // @ts-ignore
      json['elevation_data_mapped_at'],
      // @ts-ignore
      json['client_id'],
      // @ts-ignore
      json['impressions_count'],
      // @ts-ignore
      json['thumbnail_url'],
      // @ts-ignore
      json['class'],
      // @ts-ignore
      json['creating_user_title'],
      // @ts-ignore
      json['images']
    );
  }

  leftColumnViewModel(
    actionUrl: string | null, actionTitle: string | null,
    lineStringFeature: HiikerGeoJSON | null
  ): LeftColumnViewModel {
    return new LeftColumnViewModel(
      this.title,
      this.created_at.toString(),
      lineStringFeature?.features[0].distanceMeters() ?? 0,
      this.elevation_gain_meters,
      this.elapsed_seconds,
      actionUrl,
      actionTitle
    )
  }
}

