<template>
  <div v-on:click="hideElevation" class="chart-info p-1 d-flex justify-content-center">
    <small class="text-center">
      <strong>Elevation profile</strong>
      <span class=" d-none d-md-inline">: Hover/tap elevation line to track a specific point on the map.</span>
      <span class="ml-3" style="color: #af8e0e;"><strong id="toggleShow">HIDE</strong></span>
    </small>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['imperial']),
  },
  methods: {
    hideElevation() {
      const element = document.getElementById('line-chart');
      const toggleText = document.getElementById('toggleShow');
      const mapsButton = document.getElementById('maps-button');
      const locationButton = document.getElementById('location-button');

      if (element.style.height === '0px') {
        element.style.height = '150px';
        toggleText.innerHTML = 'HIDE';
        mapsButton.style.bottom = '331px';
        locationButton.style.bottom = '270px';
      } else {
        element.style.height = '0px';
        toggleText.innerHTML = 'SHOW';
        mapsButton.style.bottom = '101px';
        locationButton.style.bottom = '40px';
      }
    },
  },
};
</script>

<style scoped lang="scss">

.chart-info {
  background-color: #fecf17;
  color: #202020;

  svg {
    font-size: 18px;
  }
}

.chart-text {
  z-index: 9999;
  background: white;
  opacity: 0.6;
  width: 100%;
  height: 100%;
}
</style>
