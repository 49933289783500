import { MeasurementPrefUtility } from "../helpers/MeasurementPref";
import { distance as turfDistance, length as turfLength } from "@turf/turf";
import togpx from "togpx";

export class HiikerGeoJSON {
  type: string;
  features: Feature[];

  constructor(features: Feature[]) {
    this.type = "FeatureCollection";
    this.features = features;
  }

  toGPX(): string {
    return togpx(this.features[0], {
      creator: 'Hiiker'
    });
  }

  static fromPlannedTrailJson(lineString: any): HiikerGeoJSON {
    let geoJson = new HiikerGeoJSON([])
    let lineStrings = lineString.features.filter((feature: any) => feature.geometry.type === "LineString");
    const coordinates = lineStrings.flatMap((lineString: any) => {
      return lineString.geometry.coordinates
    })
    const feature = new Feature("Feature", new Geometry("LineString", coordinates), {})
    geoJson.features = [feature]
    return geoJson
  }

  static fromJson(lineString: any): HiikerGeoJSON {
    let geoJson = new HiikerGeoJSON([])
    geoJson.type = "FeatureCollection";
    let feature = Feature.fromJson(lineString.features[0])
    geoJson.features = [feature];
    geoJson.features[0].properties = lineString
    return geoJson;
  }

  toHiikerChartData(distanceIsMeters: Boolean = false): object {
    const chartdata: any = {
      labels: [],
      xLabels: [],
      lat: [],
      lon: [],
      datasets: [
        {
          borderColor: '#ffffff',
          borderWidth: 2,
          fill: false,
          data: [],
        },
      ],
    };
    let divider = 0;
    let coordinates = this.features[0].geometry.coordinates
    if (coordinates.length > 1000) {
      divider = Math.round(coordinates.length / 1000);
    }
    let count = 0;
    let distance = 0;
    const distances = coordinates.map((coordinate: any, index: number) => {
      if (index === 0) {
        return 0;
      } else {
        distance += Math.round(turfDistance(coordinates[index - 1], coordinate, { units: 'meters' }))
        return distance;
      }
    })
    const measurementUtils = MeasurementPrefUtility.preference()
    for (let i = 0; i < coordinates.length; i++) {
      if (count === divider) {
        count = 0;
        const coord = coordinates[i];
        chartdata.xLabels.push(measurementUtils.bigUnitFormatter(distances[i] / 1000, false));

        chartdata.labels.push(measurementUtils.smallUnitFormatter(coord[2]));
        chartdata.lat.push(coord[1]);
        chartdata.lon.push(coord[0]);
        chartdata.datasets[0].data.push(measurementUtils.smallUnitValue(coord[2]));
      } else {
        count++;
      }
    }
    return chartdata;
  }
}



export class Feature {
  type: string;
  geometry: Geometry;
  properties: object;

  constructor(type: string, geometry: Geometry, properties: object) {
    this.type = type;
    this.geometry = geometry;
    this.properties = properties;
  }

  static fromJson(feature: any): Feature {
    let geometry = Geometry.fromJson(feature.geometry)
    return new Feature(feature.type, geometry, feature.properties);
  }

  distanceMeters(): number {
    // @ts-ignore
    return turfLength(this, { units: 'meters' });
  }

  elevationGain(): number {
    return this.geometry.coordinates.map((coordinate: number[]) => coordinate[2]).reduce((a: number, b: number) => a + b, 0);
  }
}


export class Geometry {
  type: string;
  coordinates: Array<number[]>;

  constructor(type: string, coordinates: Array<number[]>) {
    this.type = type;
    this.coordinates = coordinates;
  }

  static fromJson(geometry: any): Geometry {
    return new Geometry(geometry.type, geometry.coordinates);
  }
}

