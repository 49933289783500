import { HiikerGeoJSON } from "./HiikerGeoJson";
import { LeftColumnViewModel } from "./LeftColumnViewModel";

export class HiikerPlannedTrail {
  id: number;
  title: string;
  client_id: null;
  created_at: Date;
  updated_at: Date;
  firebase_user_id: string;
  obfuscated_id: number;
  planned_trail_v1_id: null;
  description: null;
  original_planned_trail_id: null;
  calculated_duration_milliseconds: number;
  calculated_distance_meters: number;
  elevation_gain_meters: number;
  thumbnail_url: string;
  creating_user_title: string;
  images: string[] = [];


  constructor(
    id: number, title: string, client_id: null, created_at: Date, updated_at: Date, firebase_user_id: string,
    obfuscated_id: number, planned_trail_v1_id: null, description: null,
    original_planned_trail_id: null, calculated_duration_milliseconds: number, calculated_distance_meters: number,
    elevation_gain_meters: number,
    thumbnail_url: string,
    creating_user_title: string,
    images: string[] = []
  ) {
    this.id = id;
    this.firebase_user_id = firebase_user_id;
    this.title = title;
    this.client_id = client_id;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.obfuscated_id = obfuscated_id;
    this.planned_trail_v1_id = planned_trail_v1_id;
    this.description = description;
    this.original_planned_trail_id = original_planned_trail_id;
    this.calculated_duration_milliseconds = calculated_duration_milliseconds;
    this.calculated_distance_meters = calculated_distance_meters;
    this.elevation_gain_meters = elevation_gain_meters
    this.thumbnail_url = thumbnail_url
    this.creating_user_title = creating_user_title
    this.images = images
  }

  static fromJson(json: object): HiikerPlannedTrail {
    // @ts-ignore
    return new HiikerPlannedTrail(
      // @ts-ignore
      json.id,
      // @ts-ignore
      json.title,
      // @ts-ignore
      json.client_id,
      // @ts-ignore
      json.created_at,
      // @ts-ignore
      json.updated_at,
      // @ts-ignore
      json.firebase_user_id,
      // @ts-ignore
      json.obfuscated_id,
      // @ts-ignore
      json.planned_trail_v1_id,
      // @ts-ignore
      json.description,
      // @ts-ignore
      json.original_planned_trail_id,
      // @ts-ignore
      json.calculated_duration_milliseconds,
      // @ts-ignore
      json.distance_meters,
      // @ts-ignore
      json.elevation_gain_meters,
      // @ts-ignore
      json.thumbnail_url,
      // @ts-ignore
      json.creating_user_title,
      // @ts-ignore
      json.images
    );
  }

  leftColumnViewModel(
    actionUrl: string | null, actionTitle: string | null,
    lineStringFeature: HiikerGeoJSON | null
  ): LeftColumnViewModel {
    return new LeftColumnViewModel(
      this.title,
      this.created_at.toString(),
      this.calculated_distance_meters,
      this.elevation_gain_meters,
      this.calculated_duration_milliseconds / 1000,
      actionUrl,
      actionTitle
    )
  }
}
