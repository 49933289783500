import startMarker from "../../img/trail-start.svg";
import endMarker from "../../img/trail-end.svg";
import mapboxgl from 'mapbox-gl';

var trailStartMarker = null
var trailEndMarker = null

export function addStartAndEndMarkers(startCoordinates, endCoordinates) {
  const startMarkerEl = document.createElement('div');
  startMarkerEl.className = 'custom-marker';
  startMarkerEl.style.backgroundImage = `url(${startMarker})`; // Replace with your image path
  startMarkerEl.style.width = '23px';
  startMarkerEl.style.height = '23px';
  startMarkerEl.style.backgroundSize = 'cover';

  // Create custom end marker
  const endMarkerEl = document.createElement('div');
  endMarkerEl.className = 'custom-marker';
  endMarkerEl.style.backgroundImage = `url(${endMarker})`; // Replace with your image path
  endMarkerEl.style.width = '23px';
  endMarkerEl.style.height = '23px';
  endMarkerEl.style.backgroundSize = 'cover';

  removeStartAndEndMarkers();

  // Create and add the start marker
  trailStartMarker = new mapboxgl.Marker(startMarkerEl)
    .setLngLat([startCoordinates[0], startCoordinates[1]])
    .addTo(window.map);

  // Create and add the end marker
  trailEndMarker = new mapboxgl.Marker(endMarkerEl)
    .setLngLat([endCoordinates[0], endCoordinates[1]])
    .addTo(window.map);
}

export function addStartAndEndMarkerWithLayer(startCoordinates, endCoordinates) {

  // For layout draw green start marker
  if (window.map.getLayer('trail-start')) {
    window.map.removeLayer('trail-start');
  }
  window.map.addLayer({
    id: 'trail-start',
    type: 'circle',
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: startCoordinates
        }
      }
    },
    paint: {
      'circle-radius': 12, // Radius for a 24px diameter circle
      'circle-color': '#70C381', // Green color
      'circle-opacity': 1, // Full opacity
      'circle-stroke-color': '#ffffff', // Stroke color, e.g., black
      'circle-stroke-width': 2, // Stroke width in pixels
      'circle-stroke-opacity': 1 // Stroke opacity (optional, default is 1)
    }
  });

  if (window.map.getLayer('trail-end')) {
    window.map.removeLayer('trail-end');
  }

  // For layout draw red end marker
  window.map.addLayer({
    id: 'trail-end',
    type: 'circle',
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: endCoordinates
        }
      }
    },
    paint: {
      'circle-radius': 12, // Radius for a 24px diameter circle
      'circle-color': '#DB2B35', // Green color
      'circle-opacity': 1, // Full opacity
      'circle-stroke-color': '#ffffff', // Stroke color, e.g., black
      'circle-stroke-width': 2, // Stroke width in pixels
      'circle-stroke-opacity': 1 // Stroke opacity (optional, default is 1)
    }
  });
}

export function removeStartAndEndMarkerWithLayer() {
  if (window.map.getLayer('trail-start')) {
    window.map.removeLayer('trail-start');
  }
  if (window.map.getLayer('trail-end')) {
    window.map.removeLayer('trail-end');
  }
}

export function removeStartAndEndMarkers() {
  if (trailStartMarker) {
    trailStartMarker.remove();
  }
  if (trailEndMarker) {
    trailEndMarker.remove();
  }
}


