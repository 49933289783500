import Vue from 'vue';
import Vuex from 'vuex';
import BaseMapViewer from '../base_map_viewer/BaseMapViewer.vue';
import store from './store/store';
import mapboxgl from "mapbox-gl";
import { GridRef, GridRefUtils } from "../utils/helpers/GridRefPreference";
import mapToken from '../utils/helpers/MaptilerToken'
import { MapOverlayOption } from "../utils/models/MapOverlayOptions";
import { LeftColumnViewModel } from "../utils/models/LeftColumnViewModel";
import { bbox } from "@turf/turf";
import createGeoLocate from "../utils/helpers/GeoLocateHelper";
import {HiikerMapStyle} from "../utils/models/MapStyles";
import {addPolyline} from "../admin/initial_trail_map/mapbox-helper";
import 'mapbox-gl/dist/mapbox-gl.css';
import {RevenueCatUtil} from "../utils/models/RevenueCatUser";
import startMarker from "../img/trail-start.svg";
import endMarker from "../img/trail-end.svg";
import {addStartAndEndMarkers} from "../utils/helpers/StartEndMarkerHelper";
import {RevenueCatWrapper} from "../utils/RevenueCatWrapper";

Vue.use(Vuex)

declare global {
  interface Window {
    vue: Vue;
  }
}

if (document.querySelector('#planned-trail-web-viewer')) {
  window.vue = new Vue({
    store,
    render: (createElement) => {
      return createElement(BaseMapViewer, {
        props: {
          // @ts-ignore
          chartData: store.state.plannedTrail.chartData
        },
      })
    },
    async beforeCreate() {
      await this.$store.dispatch('plannedTrail/initialiseStore');
      await this.$store.dispatch('mapStyles/initialiseStore');
      // @ts-ignore
      this.setBaseMapLeftColumnViewModel()
    },
    mounted() {
      this.initialiseMap()
    },
    methods: {
      setBaseMapLeftColumnViewModel() {
        const plannedTrail = this.$store.state.plannedTrail.plannedTrail
        const lineString = this.$store.state.plannedTrail.lineString
        // @ts-ignore
        const isOwnTrail = window.user != null && window.user.firebase_user_id === plannedTrail.firebase_user_id
        const leftColumnViewModel = LeftColumnViewModel.forPlannedTrail(
          plannedTrail, lineString, isOwnTrail
        )
        this.$store.commit("baseMap/setLeftColumnViewModel", leftColumnViewModel);
      },
      async initialiseMap() {
        mapboxgl.accessToken = "pk.eyJ1IjoiaGlpa2VyLW1hcHMiLCJhIjoiY2x0YThyZHppMWhmejJqbzExODBuY3dveiJ9.nBrtnXgbzxpAx_953HCB-w"
        const map = new mapboxgl.Map(this.$store.getters["plannedTrail/mapOptions"]);
        const isProOrProPlus = await RevenueCatWrapper.isProOrProPlus();
        map.setStyle(HiikerMapStyle.getCurrentStyle(isProOrProPlus).styleUrl)
        const gridRef = GridRefUtils.preference()
        map.on('style.load', this.mapStyleLoaded);
        map.once('style.load', () => {
          if (store.state.plannedTrail.lineString !== null) {
            this.setMapBounds(store.state.plannedTrail.lineString)
            this.addPolyline()
          }
        })
        map.on('mousemove', (e) => {
          const coords = gridRef.convert(e.lngLat);
          store.commit('baseMap/setMiniCoordsBar', coords.toString())
        });
        map.on('load' , () => {
          createGeoLocate();
        });
        window.map = map;
        this.$store.dispatch('waypoints/initialiseStore');
      },
      mapStyleLoaded() {
        setTimeout(() => {
          this.addPolyline()
        }, 1000)
      },
      setMapBounds(geoJson: any) {
        const map = window.map
        const newBbox = bbox(geoJson);
        // @ts-ignore

        const bounds = mapboxgl.LngLatBounds.convert(newBbox);
        map.fitBounds(bounds, {
          padding: 200
        });
      },
      addPolyline() {
        let id = 'lineString'
        if (window.map.getSource(id) == null) {
          window.map.addSource(id, {
            type: 'geojson',
            data: this.$store.state.plannedTrail.lineString,
          });
        }
        if (window.map.getLayer(id)) {
          window.map.removeLayer(id)
        }

        window.map.addLayer({
          id: id + "-border",
          type: "line",
          source: id,
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#a72024",  // Set this to the border color you want
            "line-width": 8,          // Border width (slightly larger than the inner line)
          }
        });

        window.map.addLayer({
          id: id,
          type: "line",
          source: id,
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#DB2B35",
            "line-width": 5,
          }
        });

        const startCoordinates = this.$store.state.plannedTrail.lineString.features[0].geometry.coordinates[0]
        const endCoordinates = this.$store.state.plannedTrail.lineString.features[0].geometry.coordinates.slice(-1)[0]
        addStartAndEndMarkers(startCoordinates, endCoordinates)
      },
    },
  }).$mount('#planned-trail-web-viewer');
}
