import Vuex, { Store } from 'vuex';
import Vue from 'vue';
import PlannedTrailModule, { PlannedTrailState } from './modules/PlannedTrailState';
import { MapStylesState } from "../../base_map_styles_dialog/store/modules/BaseMapStylesDialogModule";
import MapStylesDialogModule from "../../base_map_styles_dialog/store/modules/BaseMapStylesDialogModule";
import BaseMapViewerModule, { BaseMapViewerState } from "../../base_map_viewer/store/modules/BaseMapViewerModule";
import WaypointDialogModule, {WaypointDialogState} from "../../waypoint_dialog/store/module/WaypointOptionsDialogModule";

Vue.use(Vuex)

export interface RootState {
  baseMap: BaseMapViewerState
  mapStyles: MapStylesState
  plannedTrail: PlannedTrailState
  waypoints: WaypointDialogState
}

const store: Store<RootState> = new Vuex.Store({
  modules: {
    baseMap: new BaseMapViewerModule(),
    mapStyles: new MapStylesDialogModule(),
    plannedTrail: new PlannedTrailModule(),
    waypoints: new WaypointDialogModule()
  }
})

export default store;
