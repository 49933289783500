export enum OverviewButtonTypes {
  share = 'share',
  editTrail = 'edit_trail',
  gpx = 'gpx',
  copyToPlanned = 'copy_to_planned',
}

export class OverviewButtonTypesUtils {
  overviewButtonType: OverviewButtonTypes;

  constructor(overviewButtonType: OverviewButtonTypes) {
    this.overviewButtonType = overviewButtonType;
  }

  icon(): string {
    switch (this.overviewButtonType) {
      case OverviewButtonTypes.share:
        return 'share';
      case OverviewButtonTypes.editTrail:
        return 'edit';
      case OverviewButtonTypes.gpx:
        return 'file_download';
      case OverviewButtonTypes.copyToPlanned:
        return 'arrow_circle_down';
      default:
        throw Error('Unknown Overview Button Type');
    }
  }

  colorClass(): string {
    switch (this.overviewButtonType) {
      case OverviewButtonTypes.share:
        return '#ffc107';
      case OverviewButtonTypes.editTrail:
        return '#2CC2E3';
      case OverviewButtonTypes.gpx:
        return '#f57700';
      case OverviewButtonTypes.copyToPlanned:
        return '#70c381';
      default:
        throw Error('Unknown Overview Button Type');
    }
  }

  text(): string {
    switch (this.overviewButtonType) {
      case OverviewButtonTypes.share:
        return 'Share';
      case OverviewButtonTypes.editTrail:
        return 'Edit';
      case OverviewButtonTypes.gpx:
        return 'Download GPX';
      case OverviewButtonTypes.copyToPlanned:
        return 'Copy to Planned';
      default:
        throw Error('Unknown Overview Button Type');
    }
  }
}
