import Vue from "vue";
import { Module } from "vuex";
import {LeftColumnViewModel} from "../../../utils/models/LeftColumnViewModel";

export class BaseMapViewerState {
  miniCoordsBar: string = '';
  leftColumnViewModel: LeftColumnViewModel | null = null;
}

const state = Vue.observable(new BaseMapViewerState());

const mutations = {
  setMiniCoordsBar(state: BaseMapViewerState, miniCoordsBarString: string) {
    state.miniCoordsBar = miniCoordsBarString
  },
  setLeftColumnViewModel(state: BaseMapViewerState, leftColumnViewModel: LeftColumnViewModel) {
    state.leftColumnViewModel = leftColumnViewModel
  }
}


export default class BaseMapViewerModule implements Module<any, BaseMapViewerState> {
  namespaced = true;
  state = state;
  mutations = mutations;
}

// export default Module({
//   state,
// })

// export default {
//   state,
// }
