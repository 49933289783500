import { HiikerRecordedTrail } from "../../../utils/models/HiikerRecordedTrail";
import { HiikerGeoJSON } from "../../../utils/models/HiikerGeoJson";
import { MeasurementPref, MeasurementPrefUtility } from "../../../utils/helpers/MeasurementPref";
import Vue from "vue";
import {ActionTree, Module, Store} from "vuex";
import store, {RootState} from "../store";
import graph_client from "../../../utils/graphql/graph_client";
import {GetRecordingGpxById} from "../../../utils/graphql/recorded-trail-graph";

declare global {
  interface Window {
    recording: HiikerRecordedTrail;
    lineString: HiikerGeoJSON;
    copyUrl: string;
    user: object | null;
    units: string;
  }
}

export class RecordedTrailState {
  // @ts-ignore
  copyUrl: string;
  recording: HiikerRecordedTrail | null;
  lineString: HiikerGeoJSON | null;
  chartData: object | null = null;

  constructor(recording: HiikerRecordedTrail | null, lineString: HiikerGeoJSON | null) {
    this.recording = recording;
    this.lineString = lineString;
  }
}

const state = Vue.observable(new RecordedTrailState(null, null));

const actions: ActionTree<RecordedTrailState, RootState> = {
  async initialiseStore({state}) {
    // @ts-ignore
    state.userLoggedIn = window.userLoggedIn;
    state.recording = HiikerRecordedTrail.fromJson(window.recording)
    state.lineString = HiikerGeoJSON.fromJson(window.lineString)
    state.chartData = state.lineString.toHiikerChartData(true);
    state.copyUrl = window.copyUrl
  },
  downloadGPX({state}) {
    graph_client.request(GetRecordingGpxById, {id: state.recording?.id}).then((data) => {
      const gpx = data.recordingGpx
      const title = state.recording?.title ?? "no-title"
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(gpx));
      element.setAttribute('download', title + '.gpx');
      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    })
  }
}


const mutations = {
  setRecording(state: RecordedTrailState, recording: HiikerRecordedTrail) {
    state.recording = recording
  },
  setLineString(state: RecordedTrailState, lineString: HiikerGeoJSON) {
    state.lineString = lineString
  },
  setCopyUrl(state: RecordedTrailState, copyUrl: string) {
    state.copyUrl = copyUrl
  }
}

const getters = {
  firstCoordinate(state: RecordedTrailState) {
    let firstCoordinates = state.lineString?.features[0].geometry.coordinates[0]
    if (firstCoordinates) {
      return [firstCoordinates[0], firstCoordinates[1]]
    } else {
      return [0.0, 0.0]
    }
  },
  mapOptions(state: RecordedTrailState): object {
    return {
      container: 'map', // container ID
      center: store.getters['recordedTrail/firstCoordinate'], // starting position [lng, lat] // extract from lineString data
      zoom: 10, // starting zoom
      maxPitch: 60, // limit the pitch to 85 degrees
    }
  }
};

const modules = {

}

export default class RecordedTrailModule implements Module<RecordedTrailState, RootState> {
  namespaced = true
  state = state
  actions = actions
  mutations = mutations
  getters = getters
  modules = modules
}
