export default {
  beforeCreate() {
    // @ts-ignore
    if (this.$store.state.genericMap === null) {
      const error = "Generic Map Viewer requires the genericMap to be implemented. Please refactor."
      throw new Error(error)
    }
  }
}


