import {OverviewButtonTypes, OverviewButtonTypesUtils} from "./OverviewButtonTypes";

export enum TrailTypes {
  PlannedTrail = 'planned_trail',
  RecordedTrail = 'recorded_trail',
  CurrentTrail = 'current_trail',
}

export class TrailTypesUtils {
  trailType: TrailTypes;

  constructor(trailType: TrailTypes) {
    this.trailType = trailType;
  }

  buttonTypes(isOwnTrail: Boolean): OverviewButtonTypesUtils[] {
    switch (this.trailType) {
      case TrailTypes.PlannedTrail:
        if (isOwnTrail) {
          return [
            new OverviewButtonTypesUtils(OverviewButtonTypes.editTrail),
            new OverviewButtonTypesUtils(OverviewButtonTypes.gpx),
            new OverviewButtonTypesUtils(OverviewButtonTypes.share),
          ];
        } else {
          return [
            new OverviewButtonTypesUtils(OverviewButtonTypes.copyToPlanned),
            new OverviewButtonTypesUtils(OverviewButtonTypes.gpx),
            new OverviewButtonTypesUtils(OverviewButtonTypes.share),
          ];
        }
      case TrailTypes.RecordedTrail:
        return [
          new OverviewButtonTypesUtils(OverviewButtonTypes.copyToPlanned),
          new OverviewButtonTypesUtils(OverviewButtonTypes.gpx),
          new OverviewButtonTypesUtils(OverviewButtonTypes.share),
        ];
      case TrailTypes.CurrentTrail:
        throw Error('Current Trail does not have overview buttons');
      default:
        throw Error('Unknown Trail Type');
    }
  }

  static fromString(trailType: string): TrailTypesUtils {
    switch (trailType) {
      case 'planned_trail':
        return new TrailTypesUtils(TrailTypes.PlannedTrail)
      case 'recorded_trail':
        return new TrailTypesUtils(TrailTypes.RecordedTrail)
      case 'current_trail':
        return new TrailTypesUtils(TrailTypes.CurrentTrail)
      default:
        throw Error('Unknown Trail Type');
    }
  }

  static getTrailTypeLabel(trailType: TrailTypes) {
    switch (trailType) {
      case TrailTypes.PlannedTrail:
        return 'Planned Trail';
      case TrailTypes.RecordedTrail:
        return 'Recorded Trail';
      case TrailTypes.CurrentTrail:
        return 'Current Trail';
      default:
        return 'Unknown Trail';
    }
  }
}
