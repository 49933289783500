<template>
  <div id="line-chart" style="height: 160px">
    <canvas id="elevationChart"></canvas>
  </div>
</template>

<script lang="ts">

import * as Chart from 'chart.js';
import { MeasurementPrefUtility } from '../../utils/helpers/MeasurementPref';
import mapboxgl from "mapbox-gl";

export default {
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      elevationMarker:  null,
    };
  },
  async mounted() {
    const measurementPref = await MeasurementPrefUtility.preference();
    const self = this;
    const options: Chart.ChartOptions = {
      hover: {
        mode: 'nearest',
        intersect: false,
        onHover: function (e, item) {
          if (e.type == 'mouseout') {
            self.elevationMarker.remove();
            return
          }
          if (item.length) {
            if (self.elevationMarker == null) {
              self.elevationMarker = self.createElevationMarker()
            }
            const lat = item[0]._chart.data.lat[item[0]._index]
            const lon = item[0]._chart.data.lon[item[0]._index]
            self.elevationMarker.setLngLat([lon, lat]).addTo(window.map);
          }
        },
      },
      layout: {
        padding: {
          left: 10,
          right: 15,
          top: 15,
          bottom: 5
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      elements: {point: {radius: 0}},
      scales: {
        xAxes: [{
          gridLines: {
            display: true,
            color: 'rgba(134,134,134,0.87)',
          },
          ticks: {
            fontColor: 'rgba(255,255,255,0.87)',
            fontStyle: 'bold',
          }
        }],
        yAxes: [{
          gridLines: {
            display: true,
            color: 'rgba(134,134,134,0.87)',
          },
          ticks: {
            fontColor: 'rgba(255,255,255,0.87)',
            fontStyle: 'bold',
            callback(value: number | string, index: number, values: number[] | string[]): string | number | null | undefined {
              return value + ` ${measurementPref.meterSymbol()}`;
            },
          },
        }],
      },
      legend: {display: false},
      tooltips: {
        intersect: false,
        axis: 'x',
        callbacks: {
          label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string {
            return `${tooltipItem.yLabel} ${measurementPref.meterSymbol()}`;
          }
        }
      },
    };
    const config: Chart.ChartConfiguration = {
      type: 'line',
      data: this.chartData,
      options: options,
    };
    new Chart(
      document.getElementById('elevationChart'),
      config,
    );
  },
  methods: {
    createElevationMarker() {
      const marker = new mapboxgl.Marker();
      const el = document.createElement('elevation');
      el.style.display = 'block';
      el.style.border = '3px solid #fff';
      el.style.cursor = 'pointer';
      el.style.padding = '0px';
      el.style.marginTop = '13px';
      el.style.backgroundColor = "#bbb";
      el.style.borderRadius = "100%";
      el.style.width = `${20}px`;
      el.style.height = `${20}px`;
      el.style.backgroundSize = 'cover';
      el.style.backgroundRepeat = 'no-repeat';
      el.style.backgroundPosition = 'center center';
      // @ts-ignore
      marker._element = el;
      return marker;
    }
  }
};
</script>
